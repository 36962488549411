<template>
  <div>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row justify-content-left">
          <div class="col-md-1">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.id"
                placeholder="ID"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.supplier"
                placeholder="Fornecedor"
                :options="optionsSuppliers"
                @search="fetchSupplier"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
                placeholder="Status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <v-select
                label="title"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsBankAccounts"
                v-model="filters.bank_account"
                placeholder="Conta bancária"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.created_at"
                class="form-control"
                placeholder="Data do pagamento"
                :config="{
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.period"
                class="form-control"
                placeholder="Período de vencimento"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-1 offset-md-5">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <div
      v-if="$can('Em Análise - Aprovar Baixa', 'Financeiro - ADM')"
      class="form-row justify-content-end mb-2"
    >
      <div class="col-md-3">
        Selecione os pedidos de compra que deseja dar baixa.
      </div>
      <div class="col-md-2">
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-success btn-block"
          @click="selectCompletedDates()"
        >
          <i
            v-b-tooltip.hover.top="'Dar baixa'"
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-receipt-cutoff cursor-pointer cursor mr-1"
          ></i>
          Dar baixa
        </button>
      </div>
    </div>
    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template
          v-if="$can('Em Análise - Aprovar Baixa', 'Financeiro - ADM')"
          #head(select)
        >
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template
          v-if="$can('Em Análise - Aprovar Baixa', 'Financeiro - ADM')"
          #cell(select)="{ item }"
        >
          <b-form-checkbox
            v-if="item.purchase_order_installment_status_name === 'Aprovado'"
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.uuid"
            v-model="selectedItems"
          />
        </template>

        <template #cell(supplier)="{ item }">
          <div class="strategic-units" v-if="item.supplier">
            <strong>{{ item.supplier.company_name }}</strong>
            <div>{{ item.supplier.document }}</div>
          </div>
          <div class="strategic-units" v-else>
            <strong>{{ item.customer.title }}</strong>
            <div>{{ toMaskCpf(item.customer.cpf) }}</div>
          </div>
        </template>

        <template #cell(plan)="{ item }">
          <div v-if="item.plan" class="d-block text-center">
            <strong>{{ item.plan.title }}</strong>
            <div v-if="item.document_type">{{ item.document_type.title }}</div>
          </div>
          <span v-else-if="item.order_id" class="text-danger">
            <strong class="d-block">Matrícula</strong>
            Cancelada
          </span>
        </template>

        <template #cell(installment)="{ item }">
          {{ item.installment }}/{{ item.installments }}
        </template>

        <template #cell(purchase_order_installment_status_name)="{ item }">
          <span
            :style="`background-color: ${item.purchase_order_installment_status_background}; color: ${item.purchase_order_installment_status_color};padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;`"
          >
            {{ item.purchase_order_installment_status_name }}
          </span>
        </template>

        <template #cell(document)="{ item }">
          <div
            v-if="item.document"
            style="
              display: grid;
              align-content: center;
              justify-content: center;
              align-items: center;
            "
          >
            <div>
              {{ item.document.number }}
            </div>
            <div>{{ item.document.title }}</div>
          </div>
        </template>

        <template #cell(date)="{ item }">
          {{ item.date | datePT() }}
        </template>

        <template #cell(total)="{ item }">
          {{ item.total | toCurrency }}
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex">
            <router-link
              :to="{
                name: 'orders-purchases-print',
                params: { uuid: item.purchase_order_uuid },
              }"
              v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
            >
              <feather-icon
                icon="EyeIcon"
                v-b-tooltip.hover.top="'Visualizar'"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
            <feather-icon
              @click="revertInstallmentStatus(item)"
              v-if="
                $can('Pagamento - Solicitar Baixa', 'Financeiro - ADM') &&
                item.purchase_order_installment_status_id === 4
              "
              icon="RotateCcwIcon"
              v-b-tooltip.hover.top="'Reverter baixa'"
              class="cursor-pointer cursor ml-1"
              size="16"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      id="select-dates-modal"
      title="Solicitar baixa?"
      centered
      @ok="updateInstallmentStatus"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      :ok-disabled="!selectedDate"
      hide-footer
    >
      <p class="text-center">
        Ao confirmar, você estará solicitando a baixa do pagamento.
      </p>
      <p class="text-center">
        Por favor, selecione a data de baixa antes de confirmar a solicitação.
      </p>

      <flat-pickr
        v-model="selectedDate"
        class="form-control mx-auto"
        placeholder="Selecione a data de baixa"
        :config="{
          altInput: true,
          dateFormat: 'Y-m-d',
          altFormat: 'd/m/Y',
          locale: 'pt',
          allowInput: false,
        }"
      />

      <div class="text-center mt-4">
        <b-button
          variant="success"
          @click="updateInstallmentStatus"
          :disabled="!selectedDate"
        >
          Confirmar
        </b-button>
        <b-button
          variant="danger"
          @click="$bvModal.hide('select-dates-modal')"
          class="ml-2"
        >
          Cancelar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardText,
  VBTooltip,
  BForm,
  BModal,
  BFormCheckbox,
  BSpinner,
  BFormInput,
  BFormGroup,
  BButton,
  BIcon,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import moment from "moment";
import * as XLSX from "xlsx";

import Ripple from "vue-ripple-directive";
import { maskCpf } from "@/_helpers/_urlHelper";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BIcon,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BCardSubTitle,
    BFormCheckbox,
    BCardText,
    BDropdown,
    BDropdownItem,
    CustomPaginateTable,
    BDropdownDivider,
    BForm,
    BSpinner,
    ButtonsActionsFooter,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      selectedDate: new Date(),
      selectedItems: [],
      optionsSuppliers: [],
      selectAllCheckbox: false,
      submited: false,
      textSubmit: "",
      submitedFilter: false,
      filters: {
        document: "",
        status: "",
        document_number: "",
        bank_account: "",
        supplier: "",
        period: "",
        created_at: "",
        currentPage: 1,
        perPage: 100,
        id: "",
      },
      optionsStatus: [],
      optionsPurchaseOrders: [],
      optionsBankAccounts: [],
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "select",
          label: "",
          class: "text-center",
          thStyle: { width: "20px" },
        },
        {
          key: "id",
          label: "ID",
          class: "text-center",
          thStyle: { width: "50px" },
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
        {
          key: "document",
          label: "Documento",
          class: "text-center",
          thStyle: { width: "140px" },
        },
        {
          key: "purchase_order_installment_status_name",
          label: "Situação",
          class: "text-center",
          thStyle: { width: "130px" },
        },
        {
          key: "date",
          label: "Vencimento",
          class: "text-center",
          thStyle: { width: "130px" },
        },
        {
          key: "installment",
          label: "Parcela",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "supplier",
          label: "Fornecedor",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "plan",
          label: "Histórico PC",
          class: "text-center",
          thStyle: { width: "230px" },
        },
        {
          key: "bank_account_name",
          label: "Conta Bancária",
          class: "text-center",
        },
        {
          key: "total_final",
          label: "Total",
          class: "text-center",
          thStyle: { width: "130px" },
        },
      ],
      dataItems: [],
    };
  },
  watch: {
    selectedItems(newVal) {
      const items = this.dataItems.filter(
        (item) => item.purchase_order_installment_status_id === 3
      ).length;
      if (newVal.length === 0 && items === 0) {
        this.selectAllCheckbox = false;
      } else {
        this.selectAllCheckbox = newVal.length === items;
      }
    },
  },
  methods: {
    async fetchSupplier(term) {
      if (term.length > 1) {
        this.$store.dispatch("Supplier/search", term).then((res) => {
          this.optionsSuppliers = res;
        });
      }
    },
    async downloadCSV() {
      try {
        // Filtra apenas os itens selecionados
        const selectedData = this.dataItems.filter((item) =>
          this.selectedItems.some((selectedUuid) => selectedUuid === item.uuid)
        );

        const toCSV = selectedData.map((item) => {
          return {
            ID: item.id,
            "DOCUMENTO Nº": item.document.number,
            "DOCUMENTO TIPO": item.document.title,
            "CONTA BANCÁRIA": item.bank_account_name,
            "HISTÓRICO PC": item.plan.title,
            TOTAL: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(item.total_final),
            CATEGORIA: item.category ? item.category.title : "",
            HISTÓRICO: item.description,
            FORNECEDOR: `${item.supplier.company_name} - ${item.supplier.document}`,
            PARCELAS: `${item.installment}/${item.installments}`,
            VENCIMENTO: moment(item.date, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/YYYY"
            ),
            SITUAÇÃO: item.purchase_order_installment_status_name,
          };
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(toCSV);
        XLSX.utils.book_append_sheet(wb, ws, "Tabela de Indicações");
        XLSX.writeFile(
          wb,
          `pagamentos-dar-baixa-${moment().format("DD/MM/YYYY HH:mm")}.xlsx`
        );
      } catch (error) {
        console.log(error);
      }
    },
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems
            .filter((item) => item.purchase_order_installment_status_id === 3)
            .map((item) => item.uuid)
        : [];
    },
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    selectCompletedDates() {
      this.$bvModal.show("select-dates-modal");
    },
    updateInstallmentStatus() {
      this.$store
        .dispatch("OrderPurchase/updateInstallmentStatus", {
          items: this.selectedItems,
          status: 4,
          completed_date: this.selectedDate,
        })
        .then(() => {
          this.$bvModal.hide("select-dates-modal");
          this.notifyDefault("success");
          this.getData();
        });
    },
    revertInstallmentStatus(item) {
      this.$swal({
        title: "Reverter status",
        text: "Tem certeza que deseja reverter o status desta parcela?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, quero reverter!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/revertInstallmentStatus", {
              items: [item.uuid],
            })
            .then(() => {
              this.$bvModal.hide("select-dates-modal");
              this.notifyDefault("success");
              this.getData();
            });
        }
      });
    },
    async fetchPurchaseOrders(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("OrderPurchase/forSelectOfSteps", {
            ids: [2, 3],
            term: term,
          })
          .then((res) => {
            this.optionsPurchaseOrders = res;
          });
      }
    },
    async getData() {
      this.selectedItems = [];
      const filtersData = {
        status: this.filters.status ? this.filters.status.code : "",
        bank_account: this.filters.bank_account
          ? this.filters.bank_account.code
          : "",
        document: this.filters.document,
        supplier: this.filters.supplier ? this.filters.supplier.code : "",
        period: this.filters.period,
        created_at: this.filters.created_at,
        id: this.filters.id,
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
        exportData: 0,
      };

      this.$store
        .dispatch("OrderPurchase/allPaymentStatus", filtersData)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;

            // for (let a = 0; a < this.dataItems.length; a++) {
            //   for (let n = 0; n < this.optionsSteps.length; n++) {
            //     if (this.dataItems[a].step.title === this.optionsSteps[n].title) {
            //       this.dataItems[a].step = {
            //         current: this.dataItems[a].step,
            //         next: {
            //           title: this.optionsSteps[n + 1].title,
            //           user_name: this.optionsSteps[n + 1].user_name
            //         },
            //       }
            //     }
            //   }
            // }
          }
        });
    },
  },
  async mounted() {
    this.getData();

    this.$store.dispatch("BankAccount/forSelect", { term: "" }).then((res) => {
      this.optionsBankAccounts = res;
    });

    this.$store
      .dispatch("OrderPurchase/forSelectInstallmentStatus", { ids: [3, 4] })
      .then((res) => {
        this.optionsStatus = res;
      });

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar pagamentos",
        text: "O arquivo será baixado assim que confirmar.",
        iconHtml: '<i class="bi bi-download" style="font-size: 1.5rem;"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.downloadCSV();
        }
      });
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
};
</script>

<style lang="scss" scoped>
.strategic-units {
  align-items: center;
  display: grid;
  align-content: center;
  justify-content: left;
  display: block;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
