var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c("div", { staticClass: "form-row justify-content-left" }, [
                _c("div", { staticClass: "col-md-1" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.id,
                          expression: "filters.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel", placeholder: "ID" },
                      domProps: { value: _vm.filters.id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "id", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Fornecedor",
                            options: _vm.optionsSuppliers,
                          },
                          on: { search: _vm.fetchSupplier },
                          model: {
                            value: _vm.filters.supplier,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "supplier", $$v)
                            },
                            expression: "filters.supplier",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            searchable: false,
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsStatus,
                            placeholder: "Status",
                          },
                          model: {
                            value: _vm.filters.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "status", $$v)
                            },
                            expression: "filters.status",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            clearable: true,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsBankAccounts,
                            placeholder: "Conta bancária",
                          },
                          model: {
                            value: _vm.filters.bank_account,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "bank_account", $$v)
                            },
                            expression: "filters.bank_account",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Data do pagamento",
                          config: {
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.created_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "created_at", $$v)
                          },
                          expression: "filters.created_at",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Período de vencimento",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-1 offset-md-5" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.submitedFilter
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _c("i", {
                              staticClass: "bi bi-search cursor-pointer cursor",
                            }),
                          ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.$can("Em Análise - Aprovar Baixa", "Financeiro - ADM")
        ? _c("div", { staticClass: "form-row justify-content-end mb-2" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _vm._v(" Selecione os pedidos de compra que deseja dar baixa. "),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success btn-block",
                  attrs: {
                    disabled: _vm.selectedItems.length < 1 ? true : false,
                    type: "button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectCompletedDates()
                    },
                  },
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.top",
                        value: "Dar baixa",
                        expression: "'Dar baixa'",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass:
                      "bi bi-receipt-cutoff cursor-pointer cursor mr-1",
                    staticStyle: {
                      "font-size": "1.2rem",
                      "line-height": "1.5",
                    },
                  }),
                  _vm._v(" Dar baixa "),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u(
              [
                _vm.$can("Em Análise - Aprovar Baixa", "Financeiro - ADM")
                  ? {
                      key: "head(select)",
                      fn: function () {
                        return [
                          _c("b-form-checkbox", {
                            on: { change: _vm.toggleSelectAll },
                            model: {
                              value: _vm.selectAllCheckbox,
                              callback: function ($$v) {
                                _vm.selectAllCheckbox = $$v
                              },
                              expression: "selectAllCheckbox",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                _vm.$can("Em Análise - Aprovar Baixa", "Financeiro - ADM")
                  ? {
                      key: "cell(select)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          item.purchase_order_installment_status_name ===
                          "Aprovado"
                            ? _c("b-form-checkbox", {
                                staticStyle: {
                                  "margin-left": "13px",
                                  padding: "0px",
                                  width: "20px",
                                  margin: "auto",
                                },
                                attrs: { value: item.uuid },
                                model: {
                                  value: _vm.selectedItems,
                                  callback: function ($$v) {
                                    _vm.selectedItems = $$v
                                  },
                                  expression: "selectedItems",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    }
                  : null,
                {
                  key: "cell(supplier)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.supplier
                        ? _c("div", { staticClass: "strategic-units" }, [
                            _c("strong", [
                              _vm._v(_vm._s(item.supplier.company_name)),
                            ]),
                            _c("div", [_vm._v(_vm._s(item.supplier.document))]),
                          ])
                        : _c("div", { staticClass: "strategic-units" }, [
                            _c("strong", [_vm._v(_vm._s(item.customer.title))]),
                            _c("div", [
                              _vm._v(_vm._s(_vm.toMaskCpf(item.customer.cpf))),
                            ]),
                          ]),
                    ]
                  },
                },
                {
                  key: "cell(plan)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.plan
                        ? _c("div", { staticClass: "d-block text-center" }, [
                            _c("strong", [_vm._v(_vm._s(item.plan.title))]),
                            item.document_type
                              ? _c("div", [
                                  _vm._v(_vm._s(item.document_type.title)),
                                ])
                              : _vm._e(),
                          ])
                        : item.order_id
                        ? _c("span", { staticClass: "text-danger" }, [
                            _c("strong", { staticClass: "d-block" }, [
                              _vm._v("Matrícula"),
                            ]),
                            _vm._v(" Cancelada "),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(installment)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(item.installment) +
                          "/" +
                          _vm._s(item.installments) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(purchase_order_installment_status_name)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        {
                          style:
                            "background-color: " +
                            item.purchase_order_installment_status_background +
                            "; color: " +
                            item.purchase_order_installment_status_color +
                            ";padding: 7px 10px; border-radius: 5px; display: block; align-items: center; align-content: center;",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.purchase_order_installment_status_name
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(document)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.document
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "grid",
                                "align-content": "center",
                                "justify-content": "center",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " + _vm._s(item.document.number) + " "
                                ),
                              ]),
                              _c("div", [_vm._v(_vm._s(item.document.title))]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(date)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm._f("datePT")(item.date)) + " "),
                    ]
                  },
                },
                {
                  key: "cell(total)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("toCurrency")(item.total)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.$can("Lançado - Visualizar", "Financeiro - ADM")
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "orders-purchases-print",
                                      params: {
                                        uuid: item.purchase_order_uuid,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Visualizar",
                                        expression: "'Visualizar'",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass: "cursor-pointer cursor",
                                    attrs: { icon: "EyeIcon", size: "16" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$can(
                            "Pagamento - Solicitar Baixa",
                            "Financeiro - ADM"
                          ) && item.purchase_order_installment_status_id === 4
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: "Reverter baixa",
                                    expression: "'Reverter baixa'",
                                    modifiers: { hover: true, top: true },
                                  },
                                ],
                                staticClass: "cursor-pointer cursor ml-1",
                                attrs: { icon: "RotateCcwIcon", size: "16" },
                                on: {
                                  click: function ($event) {
                                    return _vm.revertInstallmentStatus(item)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "select-dates-modal",
            title: "Solicitar baixa?",
            centered: "",
            "ok-title": "Confirmar",
            "cancel-title": "Cancelar",
            "ok-disabled": !_vm.selectedDate,
            "hide-footer": "",
          },
          on: { ok: _vm.updateInstallmentStatus },
        },
        [
          _c("p", { staticClass: "text-center" }, [
            _vm._v(
              " Ao confirmar, você estará solicitando a baixa do pagamento. "
            ),
          ]),
          _c("p", { staticClass: "text-center" }, [
            _vm._v(
              " Por favor, selecione a data de baixa antes de confirmar a solicitação. "
            ),
          ]),
          _c("flat-pickr", {
            staticClass: "form-control mx-auto",
            attrs: {
              placeholder: "Selecione a data de baixa",
              config: {
                altInput: true,
                dateFormat: "Y-m-d",
                altFormat: "d/m/Y",
                locale: "pt",
                allowInput: false,
              },
            },
            model: {
              value: _vm.selectedDate,
              callback: function ($$v) {
                _vm.selectedDate = $$v
              },
              expression: "selectedDate",
            },
          }),
          _c(
            "div",
            { staticClass: "text-center mt-4" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success", disabled: !_vm.selectedDate },
                  on: { click: _vm.updateInstallmentStatus },
                },
                [_vm._v(" Confirmar ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("select-dates-modal")
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }